import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-376535a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cp-table-list" }
const _hoisted_2 = { class: "cp-table-list__table" }
const _hoisted_3 = {
  key: 'total-key',
  class: "cp-table-list__row cp-table-list-row cp-table-list-row--total cp-table-list-row--group-total"
}
const _hoisted_4 = {
  key: 0,
  class: "cp-table-list__cell-total"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_ctx.showTotal)
        ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cols, (_, cellIndex) => {
              return (_openBlock(), _createElementBlock("span", {
                key: `row-total-cell-${cellIndex}`,
                class: _normalizeClass(["cp-table-list__cell", { 'cp-table-list__cell-with-order': _ctx.showTotalOrder }])
              }, [
                (cellIndex === 0 && _ctx.showTotalOrder)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Всего (часы/заказы):"))
                  : (cellIndex === 0 && !_ctx.showTotalOrder)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode("Всего:")
                      ], 64))
                    : _createCommentVNode("", true),
                (_ctx.showTotalOrder && cellIndex === _ctx.cols.length - 2)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createTextVNode(_toDisplayString(_ctx.totalCount), 1)
                    ], 64))
                  : (_ctx.showTotalOrder && cellIndex === _ctx.cols.length - 1)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                        _createTextVNode(_toDisplayString(_ctx.totalOrders), 1)
                      ], 64))
                    : (!_ctx.showTotalOrder && cellIndex === _ctx.cols.length - 1)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                          _createTextVNode(_toDisplayString(_ctx.totalCount), 1)
                        ], 64))
                      : _createCommentVNode("", true)
              ], 2))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataValue, (row, rowIndex) => {
        return (_openBlock(), _createElementBlock("li", _mergeProps({
          key: `row-${rowIndex}`,
          class: ["cp-table-list__row cp-table-list-row", row.class]
        }, _ctx.getAttrs(_ctx.rowAttrs, { row, rowIndex })), [
          _renderSlot(_ctx.$slots, "row", _normalizeProps(_guardReactiveProps(_ctx.getProps({ row, rowIndex }))), () => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cols, (cell, cellIndex) => {
              return (_openBlock(), _createElementBlock("span", _mergeProps({
                key: `row-${rowIndex}-cell-${cellIndex}`,
                class: ["cp-table-list__cell cp-table-list-cell", `cp-table-list-cell--${cell.value}`]
              }, _ctx.getCellAttrs(_ctx.cellAttrs, { value: row[cell.value], cell, cellIndex, row, rowIndex })), [
                _renderSlot(_ctx.$slots, `cell-${cell.value}`, _normalizeProps(_guardReactiveProps(_ctx.getProps({ value: row[cell.value], cell, cellIndex, row, rowIndex }))), () => [
                  _renderSlot(_ctx.$slots, "cell", _normalizeProps(_guardReactiveProps(_ctx.getProps({ value: row[cell.value], cell, cellIndex, row, rowIndex }))), () => [
                    _createTextVNode(_toDisplayString(row[cell.value]), 1)
                  ], true)
                ], true)
              ], 16))
            }), 128))
          ], true)
        ], 16))
      }), 128))
    ])
  ]))
}