import store from '@/store';
import PageBaseModule from '@/store/page';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { prepareCustomerStatistics } from '@/lib/Analytics';
import { getStatisticsByClient } from '@/api/analytics';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import filterModel from './filter';
import AnalyticsByCustomersFilter from './filterEntity';
import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import moment from 'moment';
import { inShiftListFirstElement } from '@/lib/Statistics';
import { strings } from '@/lib/stringConst';

export const MODULE_NAME = 'analyticsByCustomers';

export interface StatisticsParams {
  closed: Record<string, string | number>[];
  successClosedCount: number;
  statistics: Record<string, string | number>[];
  statisticsTotalCount: number;
  clientsTable: Record<string, Record<string, string | boolean | undefined>[]>;
}

export interface StatisticsByClientModel {
  [key: string]: number | Record<string, string>[];
  by_admin: number;
  by_admin_fast_replacement: number;
  by_employee: number;
  by_employee_fast_replacement: number;
  by_system: number;
  by_system_fast_replacement: number;
  clients: Record<string, string>[];
  closed_with_late: number;
  did_not_go_out: number;
  fake_call: number;
  fast_replacement: number;
  need_count: number;
  need_count_count: number;
  need_opened: number;
  need_opened_count: number;
  opened_due_to_absence: number;
  opened_due_to_absence_count: number;
  success_closed: number;
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class AnalyticsByCustomersModule extends PageBaseModule {
  statistics: Record<string, string | number>[] = [];
  statisticsTotalCount = 0;
  successClosed = 0;
  closed: Record<string, string | number>[] = [];
  filter: Filter;
  clients: Record<string, Record<string, string | number | boolean>[]> = {};

  constructor(module: AnalyticsByCustomersModule) {
    super(module);

    const filter = new filterModel();
    this.filter = getModule(AnalyticsByCustomersFilter);
    this.filter.setFilterName('analyticsByCustomersFilter');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);
  }

  @Mutation
  SET_STATISTICS(params: StatisticsParams) {
    this.successClosed = params.successClosedCount;
    this.closed = params.closed;
    this.statistics = params.statistics;
    this.statisticsTotalCount = params.statisticsTotalCount;
    this.clients = params.clientsTable as Record<string, Record<string, string | number | boolean>[]>;
  }

  @Action({ rawError: true })
  initDates() {
    const startDate = moment().add(-29, 'days').format('DD/MM/YY').split('/');

    const today = moment().format('DD/MM/YY').split('/');

    return {
      startDate: { day: startDate[0], month: startDate[1], year: startDate[2] },
      endDate: { day: today[0], month: today[1], year: today[2] },
    };
  }

  @Action({ rawError: true })
  async getStatistics() {
    try {
      const filter = this.filter.filterSettings.filter;

      if (filter.indexOf('dayStart') !== -1 || filter.indexOf('dayEnd') !== -1) {
        const result = await getStatisticsByClient(filter);

        this.updateData(result);
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  updateData(result: StatisticsByClientModel) {
    const preparedData = prepareCustomerStatistics(result) as StatisticsParams;

    this.SET_STATISTICS(preparedData);
  }

  @Action({ rawError: true })
  updateMaxPercent(result: { key: string; value: string }) {
    const tmp = { ...result };

    tmp.value = !tmp.value ? '100' : (tmp.value = tmp.value.replace(/\s|%/g, ''));

    this.filter.updateSearch(tmp);
  }

  @Action({ rawError: true })
  async init() {
    this.filter.updateSelect({
      key: 'inShift',
      value: inShiftListFirstElement,
    });

    this.filter.updateSearch({
      key: 'maxPercent',
      value: '100',
    });

    await this.filter.init();

    await this.filter.updateFilter();

    await this.getStatistics();
  }
}

export default getModule(AnalyticsByCustomersModule);
