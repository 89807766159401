import { prepareStatistics } from '@/lib/Statistic';
import { StatisticsByClientModel, StatisticsParams } from '@/store/analytics/byCustomers';

export function prepareCustomerStatistics(data: StatisticsByClientModel): StatisticsParams {
  return {
    ...prepareStatistics(data),

    clientsTable: {
      titles: [
        {
          id: 'name',
          name: 'Клиенты',
          visible: true,
        },
        {
          id: 'percent',
          name: 'Закр. заказов, %',
          visible: true,
          align: 'right',
        },
      ],
      rows: data['clients'] || [],
    },
  };
}
