import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import EntityBaseModule from '@/store/entity';
import { tabsNav } from './entityTabs';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import userModule from '@/store/user';
export const MODULE_NAME = 'analyticsEntity';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class TasksEntityModule extends EntityBaseModule {
  tabsNav: TabsNavInterface = {};

  constructor(module: TasksEntityModule) {
    super(module);
  }

  @Mutation
  SET_TABS_NAV(list: TabsNavInterface) {
    this.tabsNav = list;
  }

  @Action({ rawError: true })
  initTabsItems() {
    const tabsByUser: TabsNavInterface = {};
    let menuItem;
    let menuItemKey;
    for ([menuItemKey, menuItem] of Object.entries(tabsNav)) {
      if (userModule.canUserRead(menuItem.pathName)) {
        tabsByUser[menuItemKey] = menuItem;
      }
    }

    this.SET_TABS_NAV(tabsByUser);
  }
}

export default getModule(TasksEntityModule);
