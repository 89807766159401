import API from '@/config/api-routers';
import { makeGetRequest } from '@/api/requests';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { StatisticsByClientModel } from '@/store/analytics/byCustomers';
import { StatisticsByEmployeeModel } from '@/store/analytics/byEmployees';

/**
 * Get client  statistics
 * @param filter
 *
 * @access CAN_READ_WHOLE_CLIENTS_STATISTIC
 */
export const getStatisticsByClient = async (filter: string): Promise<StatisticsByClientModel> => {
  const assemblerUrl = new AssemblerUrl(API.analytics.byCustomer);
  const url = assemblerUrl.addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.statistics;
};

/**
 * Get employee statistics
 * @param filter
 *
 * @access CAN_READ_EMPLOYEE_STATISTICS
 */
export const getStatisticsByEmployee = async (filter: string): Promise<StatisticsByEmployeeModel> => {
  const result = await makeGetRequest(`${API.analytics.byEmployee}?${filter}`);

  return result.data.result.statistics;
};
