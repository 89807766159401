import FilterFactory from '@/lib/layouts/page/filterFactory';
import { inShiftList } from '@/lib/Statistics';

export default class CustomersStatisticsFilter {
  filterHandlers = {
    update: 'analyticsByCustomersFilter/updateFilter',
    reset: 'analyticsByCustomersFilter/resetFilter',
    resetState: 'analyticsByCustomersFilter/resetFilterState',
    removeSelected: 'analyticsByCustomersFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createDateIntervalPickerEntity('date');
    filter.setName('date', 'picker_date');
    filter.setPreviewName('date', 'Дата');
    filter.setInitAction('date', 'analyticsByCustomers/initDates');
    filter.setDateIntervalList('date', {
      startDate: {
        key: 'dayStart',
        type: 'date-picker',
        title: '',
        placeholder: '',
        value: '',
        action: 'analyticsByCustomersFilter/updateStartDate',
      },
      endDate: {
        key: 'dayEnd',
        type: 'date-picker',
        title: '',
        placeholder: '',
        value: '',
        action: 'analyticsByCustomersFilter/updateEndDate',
      },
    });

    filter.createSelectEntity('inShift');
    filter.setName('inShift', 'select_in_shift');
    filter.setAction('inShift', 'analyticsByCustomersFilter/updateSelect');
    filter.setClassName('inShift', 'cp-width--144');
    filter.setClearAction('inShift', '');
    filter.setList('inShift', inShiftList);

    filter.createSearchEntity('maxPercent');
    filter.setName('maxPercent', 'search_max_percent');
    filter.setAction('maxPercent', 'analyticsByCustomers/updateMaxPercent');
    filter.setTitle('maxPercent', 'Макс. процент закрытия.');
    filter.setOpenTitle('maxPercent', true);
    filter.setClassName('maxPercent', 'cp-width--160');
    filter.setMask('maxPercent', [
      'percentage',
      {
        rightAlign: false,
      },
    ]);

    filter.createCheckboxEntity('withAdditional');
    filter.setName('withAdditional', 'checkbox_withAdditional');
    filter.setAction('withAdditional', 'analyticsByCustomersFilter/updateCheckbox');
    filter.setListElement('withAdditional', {
      id: 'withAdditional',
      name: 'Учитывать доп. смены',
      checked: false,
    });

    filter.createCheckboxEntity('isNotTest');
    filter.setName('isNotTest', 'checkbox_isNotTest');
    filter.setPreviewName('isNotTest', 'Без тестовых');
    filter.setIgnoreNull('isNotTest', true);
    filter.setListElement('isNotTest', {
      id: 'isNotTest',
      name: 'Без тестовых',
      checked: false,
    });
    filter.setAction('isNotTest', 'analyticsByCustomersFilter/updateCheckbox');
    filter.setIconClassName('isNotTest', 'icon-is-active');

    this.filterModel = filter.filterModel;
  }
}
