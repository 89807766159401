
import { Prop, Component, Vue } from '@/lib/decorator';

@Component({})
export default class TableList extends Vue {
  @Prop({ required: true }) dataValue!: { [key: string]: string }[];
  @Prop({ required: true }) cols!: { [key: string]: string }[];
  @Prop({ default: false }) showTotal?: boolean;
  @Prop({ default: false }) showTotalOrder?: boolean;
  @Prop({}) rowAttrs?: any;
  @Prop({}) cellAttrs?: any;
  @Prop({ default: 0 }) totalCount?: number;
  @Prop({ default: 0 }) totalOrders?: number;

  getProps(local = {}) {
    const props = JSON.parse(
      JSON.stringify({
        data: this.dataValue,
        cols: this.cols,
      })
    );

    return { ...props, ...local };
  }

  getAttrs(attrs: any, props: any) {
    return typeof attrs === 'function' ? attrs(this.getProps(props)) : attrs;
  }

  getCellAttrs(attrs: any, props: any) {
    const { style = {}, ...properties } = this.getAttrs(attrs, props) || {};

    return {
      style: {
        width: props.cell.width || 'auto',
        textAlign: props.cell.align || 'left',
        ...style,
      },
      ...properties,
    };
  }
}
