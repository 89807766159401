
import { Component, Vue } from '@/lib/decorator';
import TitleReturn from '@/components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import AnalyticsByCustomersEntityModule from '@/store/analytics/byCustomers/index';
import FilterInstant from '@/components/FilterInstant.vue';
import TableList from '@/components/data/TableList.vue';
import moment from 'moment';
import EChart from '@/components/EChart.vue';
import { getPlural } from '@/lib/Utils';
import statisticChartOptions, { init as initStatisticsOption } from './StatisticChartOptions';
import closedChartOptions, { init as initClosedOption } from './ClosedChartOptions';
import AnalyticsEntityModule from '@/store/analytics/entity';
import AppTable from '@/components/ui/table/Table.vue';
import { chartOptionsInterface, chartOptionsItem } from './interfaces';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { FilterModel } from '@/lib/layouts/page/filter.interface';
import { DatePickerEntityList } from '@/lib/layouts/page/filterDatePicker.interface';

const scssVariables = {
  chartColorGreen: '#74D695',
  chartColorPurple: '#AD90D3',
  chartColorRed: '#E27F7F',
  chartColorDarkPurple: '#515184',
  chartColorBlue: '#5B93CC',
  chartColorDarkBlue: '#5870A0',
  chartColorYellow: '#E9C56A',
};

@Component({
  components: {
    AppTable,
    EChart,
    TableList,
    FilterInstant,
    TitleReturn,
    TabsNav,
  },
})
export default class AnalyticsByCustomers extends Vue {
  initStatisticsOption = initStatisticsOption;
  initClosedOption = initClosedOption;
  statisticChartOptions = statisticChartOptions;
  closedChartOptions = closedChartOptions;

  statisticsAdditional = {
    allClosed: scssVariables.chartColorGreen,
    needOpened: scssVariables.chartColorRed,
    fakeCall: scssVariables.chartColorYellow,
  };

  closedAdditional = {
    bySystem: scssVariables.chartColorDarkPurple,
    byEmployee: scssVariables.chartColorDarkBlue,
    byAdmin: scssVariables.chartColorBlue,
  };

  cols = [{ value: 'percent' }, { value: 'name', width: '100%' }, { value: 'count' }];

  get tabsNav(): TabsNavInterface {
    return AnalyticsEntityModule.tabsNav;
  }

  get currentTab(): string {
    return AnalyticsEntityModule.tabsNav.byCustomers?.id;
  }

  get model(): FilterModel {
    return AnalyticsByCustomersEntityModule.filter.filterSettings.filterModel;
  }

  get startDate(): string {
    return (AnalyticsByCustomersEntityModule.filter.filterSettings.filterModel.date.list as DatePickerEntityList)?.startDate
      .value;
  }

  get endDate(): string {
    return (AnalyticsByCustomersEntityModule.filter.filterSettings.filterModel.date.list as DatePickerEntityList)?.endDate
      .value;
  }

  get period(): number {
    const start = moment(this.startDate, 'DD/MM/YY');
    const end = moment(this.endDate, 'DD/MM/YY');
    const duration = moment.duration(end.diff(start));

    return duration.asDays() + 1;
  }

  get declOfNumWordDay(): string {
    return getPlural(this.period, ['день', 'дня', 'дней']);
  }

  get clients(): Record<string, Record<string, string | number | boolean>[]> {
    return AnalyticsByCustomersEntityModule.clients;
  }

  get statisticsTotalCount(): number {
    return AnalyticsByCustomersEntityModule.statisticsTotalCount;
  }

  get successClosed(): number {
    return AnalyticsByCustomersEntityModule.successClosed;
  }

  get statistics(): Record<string, string | number>[] {
    return AnalyticsByCustomersEntityModule.statistics;
  }

  get closed(): Record<string, string | number>[] {
    return AnalyticsByCustomersEntityModule.closed;
  }

  get statisticsChart(): chartOptionsInterface {
    return this.mergeChartWithData({
      data: this.statistics,
      template: this.statisticChartOptions,
      additional: this.statisticsAdditional,
    });
  }

  get closedChart(): chartOptionsInterface {
    return this.mergeChartWithData({
      data: this.closed,
      template: this.closedChartOptions,
      additional: this.closedAdditional,
    });
  }

  mergeChartWithData(param: {
    data: Record<string, chartOptionsItem>[];
    template: chartOptionsInterface;
    additional: { [index: string]: string };
  }): chartOptionsInterface {
    const chartData = param.data
      .filter((el: Record<string, chartOptionsItem>) => el.percent || el.percent === 0)
      .map((el: Record<string, chartOptionsItem>) => {
        return {
          value: el.percent,
          name: el.name,
          label: {
            color: param.additional[el.id as number],
          },
          itemStyle: {
            color: param.additional[el.id as number],
          },
        };
      });

    param.template.series.forEach((el: Record<string, chartOptionsItem>) => {
      el.data = chartData as chartOptionsItem;
    });

    return param.template;
  }

  getCellAttrs(): (props: { row: { group: string } }) => { class?: undefined } | { class: string[] } {
    let group: string;

    return function (props: { row: { group: string } }) {
      if (props.row.group === group) {
        return {};
      }

      group = props.row.group;

      return {
        class: ['cp-table-list-cell--divider', 'cp-table-list-cell--pt-8'],
      };
    };
  }

  returnBefore(): void {
    this.$router.push({ name: 'shops' });
  }

  async updateList(): Promise<void> {
    await AnalyticsByCustomersEntityModule.filter.updateFilter();
    await AnalyticsByCustomersEntityModule.getStatistics();
  }

  mounted(): void {
    AnalyticsEntityModule.initTabsItems();
    AnalyticsByCustomersEntityModule.init();
  }
}
