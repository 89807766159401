
import { defineComponent, PropType } from 'vue';
import ECharts from 'vue-echarts';
import { typeInitOptions } from '@/interfaces/echarts.interface';

const component = defineComponent({
  name: 'EChart',
  components: { ECharts },
  props: {
    chartOptions: {
      required: true,
      type: Object as PropType<typeInitOptions>,
    },
    initChartOptions: {
      type: Object as PropType<unknown>,
    },
  },
  methods: {
    chartClicked(params: unknown) {
      this.$emit('chart-clicked', params);
    },
  },
});

export default component;
